import React from 'react';
import i18n from "../../../i18n";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import CoverImage from '../../components/CoverImage';
import salonImage from '../../../assets/salonImage.jpg';
import SalonCard from '../../components/SalonCard';
import NavBarMenu from '../../components/NavBarMenu';
import generateMeta from '../../utils/meta';

const SalonPage = ({ data, pageContext }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];
  // i18n used for translation
  i18n(pageContext.locale);

  let SalonPageContent = [];
  if (data) {
    SalonPageContent = data.allContentfulSalonCard.edges;
  }

  return (
    <Layout
      currentPath={pageContext.currentPath}
      lang={pageContext.locale}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang="fr" />
        <title>Salons Partenaires Zawema</title>
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      <>
        <CoverImage
          imageDesktop={salonImage}
          imageMobile={salonImage}
          title="Salons"
          altDesktop="salons partenaires zawema"
          altMobile="salons partenaires zawema"
        />
      </>
      <div className='grid grid-cols-1 lg:grid-cols-2 max-w-screen-2xl mx-auto'>
        {
          (SalonPageContent.length === 0)
          && (
            <p> Aucun salon disponible pour le moment </p>
          )
        }
        {
          (SalonPageContent.length !== 0)
          && (
            SalonPageContent.map((salonContent, key) => {
              return (
                <SalonCard
                  key={key}
                  image={salonContent.node.image.file.url}
                  desc={salonContent.node.image.desc}
                  title={salonContent.node.title}
                  adress={salonContent.node.adress}
                  link={salonContent.node.link}
                  description={salonContent.node.description.description}
                  phone={salonContent.node.phone}
                />
              )
            })
          )
        }
      </div>
    </Layout>
  )
}

export default SalonPage

export const query = graphql`
    query( $locale: String! ) {
        menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
            edges {
              node {
                slug
                categoryName
              }
            }
          }
        menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                  parentCategory {
                    slug
                    categoryName
                    categoryEncartTitle
                  }
                }
              }
            }
          }
        allContentfulCategorySve(filter: { node_locale: { eq: $locale } }) {
            edges {
              node {
                categoryName
                categoryEncartTitle
                slug
                parentCategory {
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        allContentfulSalonCard(filter: {node_locale: {eq: $locale}}) {
          edges {
            node {
              image {
                description
                file {
                  url
                }
              }
              title
              adress
              link
              description {
                description
              }
              phone
            }
          }
        }
        markdownRemark(fileAbsolutePath: {regex: "/hairdresser.md/"}) {
          html
          frontmatter {
              title
              description
              ogtitle
              ogdescription
          }
        }
    }
`
import React from 'react';
import { addProtocol } from "../../utils/format";

const SalonCard = ({ image, desc, title, adress, link, description, phone }) => {

  return (
    <div className="flex flex-col my-4 h-96 w-11/12 sm:w-3/5 lg:w-10/12 lg:max-w-4xl bg-white shadow-md dark:bg-gray-800 m-auto">
      <div className="flex object-cover h-1/3 w-full">
        <img className="object-cover w-full" src={image} alt={desc} />
      </div>
      <div className="px-2 sm:px-10 flex flex-col justify-evenly md:text-left h-2/3">
        <h5 className="my-2 py-2 md:text-2xl font-bold tracking-tight text-gray-900">{title}</h5>
        <p className="whitespace-pre-line overflow-auto">{description}</p>
        <div className="my-3 py-2 flex flex-row">
          <svg className="h-4 sm:h-8 w-4 sm:w-8 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <p className="pl-2 font-normal text-gray-700 dark:text-gray-400">
            {adress}
          </p>
        </div>
        <div className="my-3 py-2 flex flex-row">
          <svg className="h-4 sm:h-8 w-4 sm:w-8 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
          </svg>
          <p className="pl-2 font-normal text-gray-700 dark:text-gray-400">{phone}</p>
        </div>
        <a href={addProtocol(link, "https")} rel="noopener noreferrer" className="text-xs py-2 font-normal underline underline-offset-4 text-gray-900">
          Lien vers le salon
        </a>
      </div>
    </div>
  );
};

export default SalonCard
